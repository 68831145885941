import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
const AboutUs = () => {
  return (
    <>
        <HelmetProvider>
            <Helmet>
                <link rel="stylesheet" href="/css/aboutus.css" />
            </Helmet>
        </HelmetProvider>
        {/* <section class="about-banner-section">
            <img src="/images/banner-aa25a75b4376f1541ca192d626a100670ab2161d22fbbdb87b6d5dab1b98c513.jpg" alt=""/>
        </section> */}
        <section class="our-story-section">
            <div class="container-fluid">
                <h2>About Us – YesBus: India’s Trusted Online Bus Ticket Booking Platform  </h2>
                {/* <p>yesbus.in is the Odisha’s largest online bus booking system that provides hassle free bus booking experience. The platform provides information, pricing, availability, and booking facility for online bus ticket booking in Odisha. You can select your desired bus seats, bus type & bus operators. You can select from wide range of available buses like Volvo, Mercedes, AC Luxury, Deluxe, Standard, Sleeper and other Government buses. Choose your destination, select bus, check seat layout, select seats, and book your ticket in just a click. You have various online payment options like the debit/credit card, net-banking and also e-wallets. You can also do instant ticket cancellation, get refund easily & rebooking. Through our website yesbus.in, we offer travel related services i.e. Online Bus Ticket Booking, Bus Hiring and Online Bus Booking for Pilgrimage tours. yesbus.in is an excellent and trustable travel brand of India for bus booking. We are dedicated to ensure excellent user experience on our site and provide 24/7 customer support at every stage to all the passengers.</p> */}
            </div>
        </section>
        <section class="aboutus-featured-section">
            <div class="container-fluid">
                <div class="aboutus-fatured-row">
                    <div class="af-left">
                        <h4>Welcome to YesBus – Your Journey Starts Here! </h4>
                        <div class="af-inner">
                            <p>At <b>YesBus</b> [https://yesbus.in], we believe that travel should be <b>seamless, affordable, and stress-free</b>. As one of India’s leading  <b>online bus ticket booking platforms</b>, we are dedicated to connecting millions of travelers with reliable bus services across the country. Whether you're planning a short commute, a weekend getaway, or a long-distance journey, YesBus ensures a  <b>smooth, convenient, and enjoyable travel experience.</b></p>
                            <p>With a <b>vast network of trusted bus operators, cutting-edge technology</b>, and a <b>customer-first approach</b>, we have revolutionized the way people book bus tickets in India. Our mission is to make bus travel <b>accessible, efficient, and comfortable</b> for everyone—from daily commuters to holiday explorers.</p>
                            <br/>
                            <p>In this <b>detailed About Us section</b>, we’ll take you through</p>
                            <p>
                                ✅ <b> Our Story & Vision  </b><br/>
                                ✅ <b> Why Choose YesBus?  </b><br/>
                                ✅ <b> Our Services & Features </b><br/>
                                ✅ <b> How YesBus Works  </b><br/>
                                ✅ <b> Safety & Reliability  </b><br/>
                                ✅ <b> Customer Support & Satisfaction </b><br/>  
                                ✅ <b> Future Goals & Expansion Plans  </b><br/>
                            </p>
                            <p>So, let’s dive in and explore what makes <b>YesBus</b> the preferred choice for <b>online bus ticket booking in India!</b>  </p>
                        </div>
                        <h4>1. Our Story & Vision </h4>
                        <div class="af-inner">
                            <p><b>The Birth of YesBus</b></p>
                            <p>YesBus was founded with a simple yet powerful idea: <b>to simplify bus travel in India</b>. India’s vast landscape relies heavily on road transport, with millions depending on buses for daily commutes and intercity travel. However, traditional bus booking methods were often <b> time-consuming, unreliable, and lacked transparency</b>.  </p>
                            <br/>
                            <p>Recognizing this gap, <b>YesBus emerged as a digital solution</b>—a platform where travelers could <b>compare, book, and manage bus tickets effortlessly</b>. Since our inception, we have grown exponentially, partnering with <b>hundreds of bus operators</b> and serving <b>lakhs of satisfied customers</b>.</p>
                            <br/>
                            <p><b>Our Vision & Mission </b></p>
                            <p><b>- Vision:</b> To become India’s most <b>trusted, customer-friendly, and technologically advanced</b> bus booking platform.</p>
                            <p><b>- Mission:</b> To provide <b>hassle-free, affordable, and safe bus travel options</b> with <b>real-time updates, secure payments, and exceptional customer service.</b>  </p>
                            <p>We are committed to <b>innovation, reliability, and customer satisfaction</b>, ensuring that every journey booked through YesBus is a <b>memorable and comfortable experience</b>.</p>
                        </div>
                        <h4>2. Why Choose YesBus? </h4>
                        <div class="af-inner">
                            <p><b>✅ Wide Network of Bus Operators</b></p>
                            <p>We collaborate with <b>premium private bus operators, state transport corporations, and luxury coach services</b> to offer a <b>diverse range of travel options</b>. Whether you need a <b>budget-friendly sleeper bus, a semi-sleeper, or a premium Volvo AC coach</b>, YesBus has it all.</p>
                            <p><b>✅ Easy & Fast Online Booking  </b></p>
                            <p>Gone are the days of standing in long queues! With <b>YesBus</b>, you can <b>book bus tickets in just a few clicks</b> via our: </p>
                            <p>
                            - <b>Website</b> ((https://yesbus.in)) <br/>
                            - <b>Mobile App</b> (https://app.yesbus.in)  
                            </p>
                            <p>Our <b>user-friendly interface</b> allows you to:  </p>
                            <p>
                                ✔ Compare bus fares & schedules <br/> 
                                ✔ Choose preferred seats  <br/>
                                ✔ Make secure payments  <br/>
                                ✔ Get instant e-tickets 
                            </p>
                            <p><b>✅ Best Prices & Exclusive Discounts </b></p>
                            <p>
                                We offer competitive pricing and regular discounts to make bus travel more affordable. Enjoy:  
                                <b>- Early Bird Discounts</b> <br/>
                                <b>- Last-Minute Deals</b> <br/>
                                <b>- Special Offers for Students & Senior Citizens</b> <br/>
                                <b>- Cashback & Wallet Benefits </b>
                            </p>
                            <p><b>✅ Safe & Secure Payment Options   </b></p>
                            <p>
                                Your security is our priority! YesBus supports multiple payment methods, including:  <br/>
                                <b>- Credit/Debit Cards</b>  <br/>
                                <b>- UPI & Net Banking</b>  <br/>
                                <b>- Digital Wallets (Paytm, PhonePe, Google Pay)</b>  <br/>
                                <b>- Cash on Board (Select Routes)</b> <br/>
                            </p>
                            <p>All transactions are <b>encrypted</b>, ensuring <b>100% secure online payments</b>.</p>
                            <p><b>✅ Real-Time Tracking & Notifications </b></p>
                            <p>Stay updated with:  <br/>
                                <b>- Live Bus Tracking (Know your bus location in real-time) </b> <br/>
                                <b>- SMS & Email Alerts (Booking confirmations, boarding points, delays)</b>  <br/>
                                <b>- Trip Reminders (Never miss your bus!)</b> <br/>
                            </p>
                            <p><b>✅ 24/7 Customer Support </b></p>
                            <p>Have a query or need assistance? Our <b>dedicated customer care team</b> is available <b>round the clock</b> via:  </p>
                            <p>
                            <b>- Phone Support</b> <br/>
                            <b>- Email & Live Chat</b> <br/>
                            <b>- Email & Live Chat</b>
                            </p>
                        </div>
                        <h4>3. Our Services & Features   </h4>
                        <div class="af-inner">
                            <p><b>🔹 Intercity & Interstate Bus Bookings  </b></p>
                            <p>
                                YesBus covers <b>thousands of routes</b> across India, including:  
                                <b>- Delhi to Jaipur, Shimla, Manali  </b> <br/>
                                <b>- Mumbai to Goa, Pune, Bangalore </b> <br/> 
                                <b>- Chennai to Hyderabad, Coimbatore</b> <br/>
                                <b>- Kolkata to Digha, Puri, Siliguri</b>  <br/>
                                <b>- Bhubaneswar to kolkata</b>
                            </p>
                            <p><b>🔹 Multiple Bus Types </b></p>
                            <p>
                                YesBus covers <b>thousands of routes</b> across India, including:  
                                <b>- AC Sleeper Coaches</b>(For long-distance comfort) <br/>
                                <b>- Non-AC & Semi-Sleeper Buses </b>(Budget-friendly) <br/> 
                                <b>- Luxury Volvo & Multi-Axle Buses</b>(Premium travel)<br/>
                                <b>- Electric & Eco-Friendly Buses</b>(Sustainable travel)
                            </p>
                            <p><b>🔹 Seat Selection & Amenities </b></p>
                            <p>
                                <b>- Choose your favorite seat </b>(Window, aisle, lower deck) <br/>
                                <b>- Onboard Amenities </b>(Wi-Fi, charging points, blankets, water bottles)
                            </p>
                            <p><b>🔹 Group Bookings & Charter Services </b></p>
                            <p>
                                Planning a <b>family trip, corporate tour, or pilgrimage</b>? YesBus offers:<br/>
                                <b>- Bulk Booking Discounts  </b><br/>
                                <b>- Private Bus Hire </b>(Customized travel solutions)
                            </p>
                        </div>
                        <h4>4. How YesBus Works – Booking in 3 Simple Steps </h4>
                        <div class="af-inner">
                            <p><b>Step 1: Search for Buses </b></p>
                            <p>Enter your <b>departure city, destination, and travel date</b> to view available buses. </p>
                            <p><b>Step 2: Compare & Select </b></p>
                            <p>Filter by <b>price, bus type, ratings, and departure time</b>. </p>
                            <p><b>Step 3: Book & Travel! </b></p>
                            <p>
                                <b>- Choose seats </b><br/>
                                <b>- Make payment </b><br/> 
                                <b>- Get e-ticket via SMS/Email</b>
                            </p>
                        </div>
                        <h4>5. Safety & Reliability </h4>
                        <div class="af-inner">
                            <p><b>🔹 Women & Family Safety </b></p>
                            <p>Special provisions for <b>female travelers & families</b>, including: </p>
                            <p>
                                <b>- Ladies-only seats  </b>
                                <b>- Emergency helpline numbers  </b>
                            </p>
                        </div>
                        <h4>6. Customer Support & Satisfaction</h4>
                        <div class="af-inner">
                            <p>We take pride in our <b>97% customer satisfaction rate</b>. Our <b>24/7 support team</b> ensures:  </p>
                            <p>
                                <b>- Instant booking assistance </b><br/>
                                <b>- Cancellation & refund processing </b><br/>
                                <b>- Travel advisory & updates </b><br/>
                            </p>
                        </div>
                        <h4>7. Future Goals & Expansion</h4>
                        <div class="af-inner">
                            <p>YesBus is continuously evolving to offer:  </p>
                            <p>
                                <b>- More Routes & Operators </b><br/>
                                <b>- AI-Powered Travel Recommendations </b><br/>
                                <b>- Loyalty Programs & Membership Benefits </b><br/>
                                <b>- Green Initiatives (Electric & Hydrogen Buses) </b>
                            </p>
                        </div>
                        <h4>Book Your Bus Tickets Today with YesBus! </h4>
                        <div class="af-inner">
                            <p>Experience <b>hassle-free, affordable, and safe bus travel</b> with India’s fastest-growing bus booking platform. Visit [https://yesbus.in] and start your journey today! </p>
                            <p><b>Happy Traveling! 🚌✨</b></p>
                        </div>

                    </div>
                    <div class="af-right">
                        <img src="/images/Blue-Bus-PNG-Clipart-Background.png" alt=""/>
                    </div>
                </div>

                {/* <div class="aboutus-fatured-row">
                    <div class="af-left">
                        <h3>What makes us unique</h3>
                        <div class="af-inner">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores suscipit corrupti, dolorem voluptatibus aliquid, minima voluptatum culpa eligendi quae harum sed iusto ullam hic ipsa illum quaerat praesentium architecto dignissimos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores suscipit corrupti, dolorem voluptatibus aliquid, minima voluptatum culpa eligendi quae harum sed iusto ullam hic ipsa illum quaerat praesentium architecto dignissimos</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores suscipit corrupti, dolorem voluptatibus aliquid, minima voluptatum culpa eligendi quae harum sed iusto ullam hic ipsa illum quaerat praesentium architecto dignissimos.</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores suscipit corrupti, dolorem voluptatibus aliquid, minima voluptatum culpa eligendi quae harum sed iusto ullam hic ipsa illum quaerat praesentium architecto dignissimos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores suscipit corrupti, dolorem voluptatibus aliquid, minima voluptatum culpa eligendi quae harum sed iusto ullam hic ipsa illum quaerat praesentium architecto dignissimos</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores suscipit corrupti, dolorem voluptatibus aliquid, minima voluptatum culpa eligendi quae harum sed iusto ullam hic ipsa illum quaerat praesentium architecto dignissimos</p>
                        </div>
                    </div>
                    <div class="af-right">
                        <img src="/images/Bus-PNG-Images-HD.png" alt=""/>
                    </div>
                </div> */}
            </div>
        </section>
        {/* <div className='row'>
            <div className='col-md-7'>
            <section class="our-story-section">
                <div class="container-fluid">
                    <h2>YESBUS- Trustworthy Online Bus Ticket Booking Platform in Odisha</h2>
                    <p>yesbus.in is the Odisha’s largest online bus booking system that provides hassle free bus booking experience. The platform provides information, pricing, availability, and booking facility for online bus ticket booking in Odisha. You can select your desired bus seats, bus type & bus operators. You can select from wide range of available buses like Volvo, Mercedes, AC Luxury, Deluxe, Standard, Sleeper and other Government buses. Choose your destination, select bus, check seat layout, select seats, and book your ticket in just a click. You have various online payment options like the debit/credit card, net-banking and also e-wallets. You can also do instant ticket cancellation, get refund easily & rebooking. Through our website yesbus.in, we offer travel related services i.e. Online Bus Ticket Booking, Bus Hiring and Online Bus Booking for Pilgrimage tours. yesbus.in is an excellent and trustable travel brand of India for bus booking. We are dedicated to ensure excellent user experience on our site and provide 24/7 customer support at every stage to all the passengers.</p>
                </div>
            </section>
            </div>
            <div className='col-md-5 about_left_side'>
                <div className='about_left_side_under_fst'>
                    <div className='about_left_side_under_fst_img'></div>
                </div>
            </div>
        </div> */}
    </>
  )
}

export default AboutUs