import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';
const ContactUs = () => {
  return (
    <>
        <HelmetProvider>
            <Helmet>
                <link rel="stylesheet" href="/css/contact.css" />
            </Helmet>
        </HelmetProvider>
        <section className="contact_new_sec">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="contact_new_sec_main">
                            <div className="row">
                                <div className="col-lg-4 col-md-5">
                                    <div className="contact_new_sec_left">
                                        <h6>Connect to <span className="yesbus_contact_span">Yes Bus</span> Now!</h6>
                                        <p>Need help booking for seats?</p>
                                        <button type="button">Contact To Us</button>
                                        <span className="contactusor">OR</span>
                                        <h5 className="mb-0">For all other Inquiries</h5>
                                        <h3><span>Email : </span>contact@yesbus.in</h3>
                                        <h3><span>Phone : </span>+91-8293373555</h3>
                                        <span className="address_span">Head Office</span>
                                        <div className="new_contact_sec_new_inner_left_body_loc_single">
											<div className="new_contact_sec_new_inner_left_body_loc_single_top">
												<h6>
                                                <FontAwesomeIcon icon={faMapMarkerAlt} />
                                                Bhubaneswar Address</h6>
												<p>Pristine Green, Pokhariput, Bhubaneswar, Odisha, 751020</p>
												<h5><a href="#"> Show Directions<i className="fal fa-long-arrow-right"></i></a></h5>
											</div>
										</div>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-7 desk_mode">
                                    <div className="contact_new_sec_right"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="banner_cont_sec desk_mode">
                    <div className="banner_cont_sec_main">
                        <h5>Cont<span>act Us</span></h5>
                    </div>
                </section>
            </div>
        </section>
    </>
  )
}

export default ContactUs